<template lang="pug">
.grid.grid-cols-2.gap-16.items-center.container.mx-auto.px-2(class="lg:grid-flow-col")
  .col-span-2.video(ref="video", class="lg:col-span-1", :class=`{
            'lg:col-start-2': flip
        }`)
    iframe.youtube-iframe(v-if="youtube", :src="'https://www.youtube.com/embed/' + youtube + '?playlist=' + youtube + '&autoplay=1&loop=1&mute=1&disablekb=1a&iv_load_policy=3&modestbranding=1&playsinline=1&start=' + start", width="100%")
      | Your browser does not support the iframe tag.
    video(v-else, autoplay, loop, muted)
      source(:src="video", type="video/mp4")
      | Your browser does not support the video tag.

  .col-span-2(class="lg:col-span-1", :class=`{
            'lg:col-start-1': flip
        }`)
    slot
</template>

<script>
export default {
  name: "landing-video-text",
  props: {
    flip: Boolean,
    video: String,
    youtube: String,
    start: Number
  },
  methods: {
    onResize() {
      const width = this.$refs.video.clientWidth;
      const height = Math.floor((width/16)*9) + 10 + "px";
      this.$refs.video.style.height = height;
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
};
</script>

<style lang="scss" scoped>
.video {
  position: relative;

  //   &::after {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     left: 0;
  //     bottom: 0;
  //     background-color: rgba(#2e2e2e, 0.2);
  //   }

  border: 4px solid #f7c83e;
}

.youtube-iframe,
video {
  width: 100%;
  height: 100%;
}
</style>