<template lang="pug">
    div.landing-hero

        div.inner(
            class='mt-32 md:mt-72 lg:mt-96'
        )
            div.container.mx-auto.grid.grid-cols-2.px-2.gap-20
                div.col-span-2(class='md:col-span-1')
                    h1.feature-font.text-white(
                        class='text-4xl sm:text-6xl md:text-7xl lg:text-8xl'
                    ) Where the best of rust are competing
                    p.text-primary-400.mt-10 Join us to experience some of the biggest raids from the biggest clans in Rust.

                    div.flex.gap-5.mt-10(class='flex-wrap md:flex-nowrap')
                        app-button.flex-grow(primary icon='fab discord' large block redirect href='https://discord.gg/vital') Discord
                        app-button.flex-grow(icon='fab tiktok' large block redirect href='https://www.tiktok.com/@vitalrustofficial') TikTok

                // This should be a carousel
                div.col-span-2.grid-cols-2.gap-5.grid(class='md:col-span-1')
                    div(
                        v-for='server in servers'
                        :key='server.ip'
                        class='col-span-2 lg:col-span-1'
                    ).bg-dark-600.bg-opacity-90.p-4.rounded.flex.flex-col.justify-between
                        div.w-full
                            h2.text-white {{ server.title }}
                            p.text-gray-400.font-bold {{ server.players }} / {{ server.maxPlayers }} 

                        app-button(block icon='external-link-alt' :href='"steam://connect/" + server.ip').mt-3 Connect

        div.landing-video
            div.gradient
            video(autoplay loop muted)
                source(src='https://static.vitalrust.com/landing-video.mp4' type='video/mp4')
                | Your browser does not support the video tag.
</template>

<script>
import serversJson from '@/assets/servers.json';

export default {
    name: 'landing-hero',
    data() {
        return {
            servers: serversJson
        }
    },
    methods: {
        async fetchMetrics(id) {
            try {
                let metrics = (await fetch('https://api.battlemetrics.com/servers/' + id).then(response => response.json())).data;
                return {
                    players: metrics.attributes.players,
                    maxPlayers: metrics.attributes.maxPlayers
                }
            } catch (err) {
                return {
                    players: '?',
                    maxPlayers: '?'
                }
            }
        }
    },
    async mounted() {
        this.servers.forEach(async server => {
            let metrics = await this.fetchMetrics(server.id)

            server.players = metrics.players;
            server.maxPlayers = metrics.maxPlayers;
        })
    }
}
</script>

<style lang="scss" scoped>

.inner {
    z-index: 10;
    position: relative;
}

.landing-video {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;

    video {
        max-height: 700px;
        width: 100%;
        object-fit: cover;
    }

    .gradient {
        position: absolute;
        top: 0; right: 0; left: 0; bottom: 0;
        background-image: linear-gradient(
            rgba(#2E2E2E, .95) 10%,
            rgba(#2E2E2E, .2),
            rgba(#2E2E2E, .8) 80%,
            rgba(#2E2E2E, 1),
        );
    }
}


</style>