var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.component,{tag:"component",staticClass:"btn",class:{
        'primary': _vm.primary,
        'secondary': _vm.secondary,
        'fab': _vm.fab,
        'text': _vm.text,
        'large': _vm.large,
        'block': _vm.block
    },attrs:{"to":_vm.to ? _vm.to : undefined,"href":_vm.component === "a" ? _vm.href : undefined,"disabled":_vm.disabled,"tag":_vm.to ? "button" : undefined,"title":_vm.title,"target":(_vm.href && _vm.redirect) ? "_blank" : undefined}},[(_vm.icon)?_c('app-icon',{attrs:{"icon":_vm.loading ? "spinner" : _vm.icon,"spin":_vm.loading,"left":!_vm.fab,"large":_vm.fab}}):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }