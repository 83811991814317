<template lang="pug">
    div.bg-dark-600
        div.container.mx-auto.px-2.py-24
            h1.feature-font.text-6xl.text-white Become a supporter
            p.mt-6.text-primary-400(class='md:w-2/3') Wanting to get a head of the queue? Visit our store to view all of our perks available for purchases.
            .mt-8
            app-button(icon='gem' primary large :href="storeUrl") Store

            //- div.flex.flex-wrap.mt-24.gap-24

            //-     div(
            //-         v-for='pkg in packages'
            //-         :key='pkg.title'
            //-     )
            //-         div.w-96.h-48.bg-dark-700.rounded
            //-         h3.feature-font.text-4xl.text-white.mt-12 {{ pkg.title }}
            //-         p.text-primary-400.mt-4 {{ pkg.desc }}
            //-         app-button.mt-4(icon='external-link-alt' :href='pkg.href') View more
</template>

<script>
export default {
    name: 'landing-VIP',
    data() {
        return {
            storeUrl: process.env.VUE_APP_STORE_URL,
            packages: [
                { title: 'Queue Skip', desc: 'Skip the queue on our most popular servers.', href: '' },
                { title: 'Weekly Pass', desc: 'Skip the queue on our most popular servers.', href: '' },
                { title: 'Vital Supporter', desc: 'Skip the queue on our most popular servers.', href: '' }
            ]
        }
    }
}
</script>