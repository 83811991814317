<template lang="pug">
    div#app.min-h-screen.flex.flex-col
    
        .flex-grow
            navigation
            div(style='padding: 0.05px')
                router-view

        app-footer
</template>

<script>
import Navigation from './components/App/Navigation';
import AppFooter from './components/App/Footer';

export default {
    components: {
        Navigation,
        AppFooter
    }
}
</script>