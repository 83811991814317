<template lang="pug">
footer.bg-dark-600
  .container.mx-auto.px-2.grid.grid-cols-4.py-12.gap-8(class='md:gap-28')
    div(class='col-span-4 sm:col-span-2')
      img.w-32.-my-8(src="@/assets/vital/wordmark_light.svg")
      h3.feature-font.text-primary-400.text-3xl.mt-4 Vital Rust
      p.text-gray-300.mt-2 Vital Rust &copy; {{ new Date().getFullYear() }}

    div(class='col-span-4 sm:col-span-2 text-left sm:text-right')
      h3.text-primary-400.mb-4.font-bold.text-lg Socials
      ul.footer-list
        li
          a.text-gray-300(href="https://discord.gg/vital")
            app-icon.mr-3(icon="fab discord")
            | Discord
        li
          a.text-gray-300(href="https://twitter.com/playvitalrust")
            app-icon.mr-3(icon="fab twitter")
            | Twitter
        li
          a.text-gray-300(href="https://www.tiktok.com/@vitalrustofficial")
            app-icon.mr-3(icon="fab tiktok")
            | TikTok
        li
          a.text-gray-300(href="https://www.youtube.com/channel/UCcjygHqq4b0-kgRC7ygXGYA/featured")
            app-icon.mr-3(icon="fab youtube")
            | YouTube

    //- div
    //-     h3.text-primary-400.mb-4.font-bold.text-lg Partners
    //-     ul.footer-list
    //-         li
    //-             a(href='#').text-gray-300 Partner #1
    //-         li
    //-             a(href='#').text-gray-300 Partner #2
    //-         li
    //-             a(href='#').text-gray-300 Partner #3
    //-         li
    //-             a(href='#').text-gray-300 Partner #4
    //-         li
    //-             a(href='#').text-gray-300 Partner #5

    //- div
    //-     h3.text-primary-400.mb-4.font-bold.text-lg Servers
    //-     ul.footer-list
    //-         li(
    //-             v-for='server in servers'
    //-             :key='server.ip'
    //-         )
    //-             a(href='#').text-gray-300 
    //-                 app-icon(icon='external-link-alt').mr-3
    //-                 | {{ server.title }}

    //- div
    //-     h3.text-primary-400.mb-4.font-bold.text-lg Contact
    //-     ul.footer-list
    //-         li
    //-             a(href='mailto:contact@vitalrust.com').text-gray-300 contact@vitalrust.com
</template>

<script>
// import serversJson from '@/assets/servers.json';

export default {
  name: "app-footer",
  // data() {
  //     return {
  //         servers: serversJson
  //     }
  // }
};
</script>

<style lang="scss" scoped>
.footer-list {
  li {
    @apply mb-2;
  }
}
</style>