<template lang="pug">

    div(class='fixed md:relative').left-0.right-0.z-50

        nav.nav.flex.flex-wrap.gap-5.pt-5.items-center.justify-between.container.mx-auto
            img.w-14(src='@/assets/vital/lettermark_light.svg' alt='Vital Rust')

            div.bg-dark-600.rounded.justify-between.items-center.h-10.flex-grow(class='hidden md:flex')
                div
                    app-button(
                        v-for='item in items'
                        :key='item.title'
                        :icon='item.icon' 
                        :to='item.to'
                        :href='item.href'
                        :active='item'
                        :primary='item.to === path'
                        :secondary='item.to !== path'
                    ) {{ item.title }}

                app-button(icon='fab steam' :href='steamAuthUrl' v-if='!loggedIn && authChecked') Login via Steam
                app-button(:icon='authChecked ? "user" : "spinner"' v-else to='/account' :primary='"/account" === path')
                    template(v-if='authChecked') {{ userInfo.username }}
                    template(v-else) Loading...

            div(class='md:hidden' v-click-outside='handleClickOutside')
                app-button(:icon='showNavMenu ? "times" : "bars"' fab @click.native='toggleNavMenu')

                div.mobile-nav.flex.flex-col.gap-4.opacity-0.hidden(
                    ref='mobileNav'
                )
                    h1.text-white.mb-2.text-4xl.feature-font.text-center Menu
                    app-button(
                        v-for='item in items'
                        :key='item.title'
                        block
                        :icon='item.icon' 
                        :to='item.to'
                        :href='item.href'
                        :active='item'
                    ) {{ item.title }}
                    app-button(icon='fab steam' :href='steamAuthUrl' v-if='!loggedIn && authChecked' block) Login via Steam
                    app-button(icon='user' v-else to='/account' block)
                        template(v-if='authChecked') {{ userInfo.username }}
                        template(v-else) Loading...

</template>

<script>
import ClickOutside from 'vue-click-outside'

import { mapGetters } from 'vuex';

export default {
    name: 'app-navigation',
    mounted() {
        this.path = this.$route.path ?? null;
    },
    data() {
        return {
            items: [
                { title: 'Home', icon: 'home', to: '/' },
                { title: 'Link Accounts', icon: 'link', to: '/link-accounts' },
                { title: 'Store', icon: 'gem', href: process.env.VUE_APP_STORE_URL },
                { title: 'Stats', icon: 'chart-line', to: '/stats' }
            ],
            steamAuthUrl: process.env.VUE_APP_AUTH_API + '/login?callbackUrl=' + process.env.VUE_APP_FRONTEND + '/account&errorUrl=' + process.env.VUE_APP_FRONTEND + '/account',
            showNavMenu: false,
            path: null
        }
    },
    methods: {
        toggleNavMenu() {
            this.showNavMenu = !this.showNavMenu;

            if(this.showNavMenu) {
                this.$refs.mobileNav.style.display = 'flex';
                setTimeout(() => {
                    this.$refs.mobileNav.style.opacity = '100%';
                }, 1)
            } else {
                this.$refs.mobileNav.style.opacity = '0%';
                setTimeout(() => {
                    this.$refs.mobileNav.style.display = 'none';
                }, 200)
            }
        },
        handleClickOutside() {
            if(this.showNavMenu) this.toggleNavMenu();
        }
    },
    computed: mapGetters( 'user', [ 'loggedIn', 'userInfo', 'authChecked' ] ),
    directives: {
        ClickOutside
    },
    watch: {
        '$route' (to, from) {
            this.path = to.path;
        }
    }
}
</script>

<style lang="scss" scoped>

.mobile-nav {
    @apply
        fixed top-0 left-0 p-8 w-4/5 
        bg-primary-400 bg-opacity-50 h-screen
        transition duration-300;
}

</style>