<template lang="pug">
    font-awesome-icon.icon(
        :icon='icon.split(" ").length > 1 ? icon.split(" ") : icon'
        :class=`{
        'mr-3': left,
        'ml-3': right
        }`
        :size='size'
        :spin='spin'
    )
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    name: 'app-icon',
    components: {
        FontAwesomeIcon
    },
    props: {
        spin: Boolean,
        icon: String, // font-awesome icon
        left: Boolean, // Adds margin-right to space the icon from any text
        right: Boolean, // Does the margin-left equivalent
        large: Boolean // Makes the icon larger
    },
    computed: {
        size: function() {
            return this.large ? "2x" : undefined
        }
    }
}
</script>