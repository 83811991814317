<template lang="pug">
    component.btn(
        :is='component'
        :to='to ? to : undefined'
        :href='component === "a" ? href : undefined'
        :disabled='disabled'
        :tag='to ? "button" : undefined'
        :title='title'
        :target='(href && redirect) ? "_blank" : undefined'
        :class=`{
            'primary': primary,
            'secondary': secondary,
            'fab': fab,
            'text': text,
            'large': large,
            'block': block
        }`
    )
        app-icon(v-if='icon' :icon='loading ? "spinner" : icon' :spin='loading' :left='!fab' :large='fab')
        slot
</template>

<script>
export default {
    name: 'app-button',
    props: {
        loading: Boolean,
        text: Boolean,
        secondary: Boolean,
        to: String,
        href: String,
        disabled: Boolean,
        primary: Boolean,
        icon: String,
        fab: Boolean,
        large: Boolean,
        redirect: Boolean,
        block: Boolean,
        title: String
    },
    computed: {
        component: function() {
            if (this.disabled) return 'button';
            else if (this.to) return 'router-link';
            else if (this.href) return 'a';
            else return 'button'
        }
    }
}
</script>

<style lang="scss" scoped>

.btn {
    @apply 
        bg-dark-700 text-primary-400 px-6 py-2 rounded 
        font-medium transition duration-200 ease-in-out
        hover:bg-dark-800 outline-none text-center break-normal inline-block;

    &.primary {
        @apply
            bg-primary-400 text-dark-800 
            hover:bg-primary-600;
    }

    &.secondary {
        @apply bg-dark-600 hover:bg-dark-800;
    }

    &.fab {
        @apply bg-transparent px-2 py-1;
    }

    &.text {
        @apply bg-transparent hover:bg-dark-700;
    }

    &.large {
        @apply text-xl px-8 py-3;
    }

    &.block {
        @apply w-full;
    }




}
</style>