import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faUser,
    faHome,
    faLink,
    faBars,
    faSignOutAlt,
    faGem,
    faTimes,
    faExternalLinkAlt,
    faSpinner,
    faChartLine
} from '@fortawesome/free-solid-svg-icons';

import {
    faSteam, faTwitch, faTwitter, faDiscord, faTiktok, faYoutube
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faUser,
    faHome,
    faLink,
    faBars,
    faSignOutAlt,
    faSteam,
    faTwitch,
    faTwitter,
    faDiscord,
    faYoutube,
    faTiktok,
    faGem,
    faChartLine,
    faTimes,
    faExternalLinkAlt,
    faSpinner
);